$sidecolor: #1eb800;
$buttoncolor: #30c07b;
$buttonred: #fd1600;
.sale-items{
    margin-top: 15px;
    .items{
        background-color: #fff;
        padding: 20px;
        box-shadow: 0px 2px 5px #dbdada;
        border-radius: 5px;
        img{
            height: 95px;
            width: 100%;
            object-fit: contain;
            margin: 10px 0 15px 0;
            cursor: pointer;
        }
        h5{
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin: 0;
            color: #6f7a8b;
            font-size: 18px;
            cursor: pointer;
        }
        p{
            line-height: 12px;
            font-size: 10px;
            margin: 0;
            height: 23px;
            overflow: hidden;
            color: #888;
            cursor: pointer;
        }
        label{
            margin: 0;
            line-height: 26px;
            font-size: 20px;
            font-weight: 600;
            color: #22263e;
            cursor: pointer;
        }
        button{
            background-color: $buttoncolor;
            border: none;
            clear: both;
            display: block;
            margin-top: 8px;
            line-height: 35px;
            width: 108px;
            text-align: center;
            border-radius: 6px;
            color: #fff;
            text-transform: uppercase;
            font-weight: 500;
            font-size: 11px;
            box-shadow: 0 4px 6px #ccc;
        }
    }
}

.sale-items{
    .sale-items-final{
        background-color: #fff;
        padding: 20px;
        box-shadow: 0px 2px 15px #999;
        border-radius: 5px;
        .cart-items{
            .first-box{
                width: 125px;
                float: left;
                img{
                    width: 100%;
                    height: 80px;
                    object-fit: contain;
                }
                label{
                    color: #292d43;
                    margin: 0;
                    line-height: 40px;
                    width: 100%;
                    text-align: center;
                    font-size: 17px;
                    font-weight: 500;
                }
            }
            .second-box{
                width: calc(100% - 175px);
                float: left;
                overflow: hidden;
                h5{
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    margin: 0;
                    color: #6f7a8b;
                    font-size: 18px;
                }
                p{
                    line-height: 12px;
                    font-size: 10px;
                    margin: 0;
                    height: 23px;
                    overflow: hidden;
                    color: #888;
                }
                label{
                    margin: 0;
                    line-height: 26px;
                    font-size: 20px;
                    font-weight: 600;
                    color: #22263e;
                }
                .mult{
                    width: 100%;
                    float: left;
                    clear: both;
                    button{
                        display: block;
                        float: left;
                        width: 28px;
                        height: 30px;
                        font-size: 18px;
                        font-weight: 800;
                        color: #fff;
                    }
                    .minus{
                        border: 1px solid $buttonred;
                        background-color: $buttonred;
                        border-radius: 1px 0;
                        border-radius: 10px 0 0 10px;
                    }
                    .plus{
                        border: 1px solid $sidecolor;
                        background-color: $sidecolor;
                        border-radius: 0 10px 10px 0;
                    }
                    input{
                        display: block;
                        float: left;
                        width: 45px;
                        padding: 0;
                        text-align: center;
                        line-height: 30px;
                        font-size: 15px;
                        font-weight: 800;
                        border: 1px solid #d1d1d1;
                        color: $sidecolor;
                        height: 30px;
                    }
                }
            }
            .remove-from-cart{
                background: url('../img/delete.png') no-repeat center;
                height: 35px;
                width: 35px;
                background-size: contain;
                cursor: pointer;
                float: right;
                margin-top: 30px;
            }
        }
    }
    .sale-items-support{
        width: 100%;
        textarea{
            width: 100%;
            border: 1px solid #d1d1d1;
            font-size: 11px;
            padding: 5px 10px;
            height: 50px;
        }
    }
}
.order-clear-box{
    background: #f6f5f2 !important;
    padding: 0 !important;
    .cart-clear-div{
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 20px;
        background-color: #fff;
        .cart-clear{
            background-color: $buttoncolor;
            line-height: 35px;
            color: #fff;
            text-transform: capitalize;
            font-weight: 500;
            font-size: 16px;
            text-align: center;
            width: 100px;
            border-radius: 5px;
            box-shadow: 0 5px 5px #ccc;
            cursor: pointer;
        }
    }
    .cart-total{
        font-size: 12px;
        text-align: right;
        padding: 15px 15px 0 0;
        color: #000;
        strong{
            font-size: 20px;
        }
    }
}