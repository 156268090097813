$buttonbg: #fd1600;
header{
    background: linear-gradient(rgba(25, 25, 25, .5), rgba(25, 25, 25, .5)), url('../img/head-img.png') no-repeat center rgb(65, 65, 65);
    height: 225px;
    background-size: cover;
    width: 100%;
    .top-head{
        height: 225px;
        padding-top: 5px;
        .top-head-row{
            height: 40px;
            overflow: hidden;
            img{
                height: 40px;
            }
            a{
                line-height: 40px;
                font-size: 18px;
                font-weight: 700;
                float: right;
                color: #fff;
                text-decoration: none;
                label{
                    margin: 0;
                    padding: 0;
                }
                img{
                    display: none;
                }
            }
        }
        .message-box{
            width: 100%;
            height: 85px;
            span{
                width: 100%;
                text-align: center;
                display: inline-block;
                margin-top: 15px;
                font-size: 20px;
                text-transform: capitalize;
                color: #fff;
                font-weight: 400;
                line-height: 35px;
                background: $buttonbg;
            }
        }
        h2{
            text-transform: uppercase;
            width: 100%;
            text-align: center;
            font-size: 40px;
            line-height: 50px;
            font-weight: 700;
            color: #fff;
            margin: 0;
            padding: 0;
            span{
                width: 100%;
                display: inline-block;
                font-weight: 300;
            }
        }
    }
}
@media (max-width: 575.98px) {
    .top-head{
        .top-head-row{
            a{
                label{
                    display: none !important;
                }
                img{
                    display: block !important;
                }
            }
        }
        h2{
            font-size: 35px !important;
            line-height: 40px !important;
        }
    }
}