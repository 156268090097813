$sidecolor: #30c07b;
.category-menu{
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
    cursor: pointer;
    .category-items{
        padding: 10px 15px;
        height: 45px;
        background: #f1f1f1;
        border-radius: 25px;
        filter: grayscale(100%);
        img{
            height: 25px;
            width: 25px;
            margin-right: 5px;
            object-fit: contain;
            float: left;
        }
        label{
            font-size: 22px;
            line-height: 25px;
            margin: 0;
            float: left;
            cursor: pointer;
        }
    }
    .active{
        filter: grayscale(0%);
        background-color: $sidecolor;
        color: #fff;
        border-radius: 25px;
    }
    .scroll-menu-arrow{
        margin-top: -4px;
        .arrow-prev{
            background: url('../img/left-arrow.png') no-repeat center;
            height: 35px;
            width: 25px;
            background-size: contain;
        }
        .arrow-next{
            background: url('../img/right-arrow.png') no-repeat center;
            height: 35px;
            width: 25px;
            background-size: contain;
        }
    }
}