@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
html body {
  background: #f3f6fa;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
}
.error404{
  width: 90%;
  height: 100vh;
  display: block;
  margin: auto;
  padding: 5% 0;
  object-fit: contain;
}
