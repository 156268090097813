$sidecolor: #30c07b;
.search-box{
    margin: 15px 0;
    button{
        background: url('../img/magnifying-glass.png') no-repeat center;
        width: 25px;
        height: 25px;
        position: absolute;
        border: none;
        margin-top: 10px;
        margin-left: 10px;
        background-size: contain;
    }
    input{
        width: 100%;
        border: 2px solid $sidecolor;
        font-size: 16px;
        line-height: 35px;
        color: #333;
        padding: 2px 35px;
        border-radius: 25px;
    }
    span{
        background: #bfbfbf;
        color: #333;
        width: 25px;
        display: block;
        height: 25px;
        line-height: 22px;
        text-align: center;
        border-radius: 50%;
        position: relative;
        float: right;
        margin-top: -34px;
        margin-right: 10px;
        cursor: pointer;
    }
}
.order-cart{
    position: fixed;
    width: 55px;
    height: 55px;
    right: 15px;
    bottom: 15px;
    background: url('../img/cart.png') no-repeat center #fd1600;
    border-radius: 50%;
    background-size: 62%;
    cursor: pointer;
    label{
        background-color: #19a300;
        color: #fff;
        border: none;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        font-size: 11px;
        font-weight: 500;
        margin-left: 15px;
        margin-top: -15px;
        position: absolute;
        cursor: pointer;
    }
}
.whatsapp-share{
    width: 100%;
    margin: 20px 0;
    clear: both;
    float: left;
    button{
        cursor: pointer;
        text-decoration: none;
        background-color: #199b5a;
        color: #fff;
        line-height: 40px;
        display: block;
        height: 45px;
        width: 220px;
        font-size: 16px;
        text-transform: capitalize;
        padding: 0 15px;
        float: right;
        border-radius: 5px;
        box-shadow: 0 5px 5px #ccc;
        border: none;
        i{
            background: url('../img/whatsapp.png') no-repeat center;
            height: 40px;
            width: 40px;
            display: block;
            background-size: 32px;
            float: left;
            cursor: pointer;
        }
        label{
            margin: 0;
            padding: 0;
            display: block;
            float: right;
            cursor: pointer;
        }
    }
}
.con-pol-order{
    padding-top: 25px;
    padding-bottom: 50px;
    .pol-order{
        display: block;
        float: right;
        border: none;
        line-height: 35px;
        height: 35px;
        width: calc((100% - 30px) / 2);
        text-align: center;
        font-size: 16px;
        color: #fff;
        font-weight: 500;
        background-color: #ccc;
        border-radius: 5px;
        box-shadow: 0 5px 5px #ccc;
    }
    span{
        display: block;
        float: right;
        line-height: 35px;
        width: 30px;
        text-align: center;
        font-weight: 500;
    }
    .active{
        background-color: #19a300;
    }
    .pol-order-bot{
        clear: both;
        margin-top: 20px;
    }
}
.sale-items{
    .address-tab{
        padding-right: 20px;
        label{
            margin: 0;
            font-size: 15px;
            text-transform: capitalize;
            font-weight: 500;
            line-height: 25px;
        }
        textarea{
            width: 100%;
            padding: 5px 15px;
            font-size: 12px;
            height: 100px;
        }
    }
}