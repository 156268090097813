$bgcolor: #30c07b;
.home-section{
    background-color: $bgcolor;
    height: 100vh;
    .home-fluid{
        height: 100vh;
        position: relative;
        .home-fluid-inner{
            width: 100%;
            margin: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            .home-logo{
                background: url('../img/plate.png') no-repeat center;
                background-size: cover;
                width: 250px;
                height: 250px;
                object-fit: contain;
                padding: 80px;
                display: block;
                margin: auto;
            }
            h2{
                color: #ffffff;
                text-align: center;
                font-size: 32px;
                line-height: 40px;
                font-weight: 300;
                strong{
                    color: #ffffff;
                    text-transform: uppercase;
                    font-weight: 700;
                }
            }
            .home-link{
                color: $bgcolor;
                background-color: #ffffff;
                text-align: center;
                padding: 10px 20px;
                font-size: 20px;
                text-transform: uppercase;
                display: block;
                margin: 25px auto;
                width: 185px;
                line-height: 30px;
                border-radius: 7px;
                box-shadow: 3px 3px 8px #666;
                text-decoration: none;
                font-weight: 500;
            }
            .tables{
                width: 100%;
                text-align: center;
                a{
                    margin: 0;
                    text-transform: capitalize;
                    font-size: 16px;
                    line-height: 30px;
                    color: #666;
                    text-shadow: 0px 0px 5px #fff;
                    text-decoration: none;
                }
            }
        }
    }
}
@media (max-width: 575.98px) {
    .home-fluid-inner{
        h2{
            font-size: 26px !important;
            line-height: 32px !important;
        }
    }
}